import React from "react"
import { graphql } from "gatsby"
import '../styles/main.scss'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <div className="container mt-xl mb-xl">
      <div className="blog-post ml-md mr-md">
        <h2>{frontmatter.title}</h2>
        <p className="is-italic">{frontmatter.date}</p>
        <p className="has-text-grey-light">{frontmatter.description}</p>
        <hr className="mt-md mb-xl" />
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 250)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
      }
    }
  }
`